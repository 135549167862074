import React from 'react'
import Header from './Layout/Header'
import Footer from './Layout/Footer'

export default (props) => (
    <div className="main-container">
        <Header />
        <main>
            {props.children}
        </main>
        <Footer />
    </div>
)