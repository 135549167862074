import React from "react"
import moment from "moment"
import Icon from "@mdi/react"
import {
  mdiFacebook,
  mdiTwitter,
  mdiInstagram,
  mdiYoutube,
  mdiLinkedin,
} from "@mdi/js"
import { ButtonPurp } from "../UI"

export default () => (
  <footer>
    <div className="mx-auto py-10 bg-dark">
      <div className="mx-auto max-w-screen-lg flex flex-wrap">
        <div className="w-full md:w-1/3 px-5 flex justify-start xs:justify-center mb-4 md:mb-0">
          <div>
            <p className="font-heading text-purp-100 text-lg pb-2">About Us</p>
            <p className="text-white text-sm font-light">
              Kevin Smith Transportation Group is the Philadelphia area’s
              highest rated and most trusted limousine and transportation
              company. Our services include weddings, airport transfers,
              corporate travel, nights on the town and more.
            </p>
          </div>
        </div>
        <div className="w-full xs:w-1/2 md:w-1/3 px-5 flex justify-start xs:justify-center mb-4 md:mb-0">
          <div>
            <p className="font-heading text-purp-100 text-lg pb-2">
              Contact Info
            </p>
            <p className="text-white text-sm font-light pb-3">(610) 222-6225</p>
            <p className="text-white text-sm font-light pb-3">
              reservations@kstglimo.com
            </p>
            <p className="text-white text-sm font-light">
              565 Hollow Rd. Suite B <br></br> Phoenixville, Pa 19460
            </p>
          </div>
        </div>
        <div className="w-full xs:w-1/2 md:w-1/3 px-5 flex justify-start xs:justify-center mb-4 md:mb-0">
          <div>
            <p className="font-heading text-purp-100 text-lg pb-2">Connect</p>
            <div className="flex pb-3">
              <a
                href="https://www.facebook.com/kevinsmithgroup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  path={mdiFacebook}
                  size={1.2}
                  className="mr-2 fill-current text-white hover:text-purp-100 transition duration-200 ease-in-out"
                />
              </a>
              <a
                href="https://www.twitter.com/kevinsmithgrp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  path={mdiTwitter}
                  size={1.2}
                  className="mr-2 fill-current text-white hover:text-purp-100 transition duration-200 ease-in-out"
                />
              </a>
              <a
                href="https://www.instagram.com/kevinsmithgrp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  path={mdiInstagram}
                  size={1.2}
                  className="mr-2 fill-current text-white hover:text-purp-100 transition duration-200 ease-in-out"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/kevin-smith-transportation-group/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  path={mdiYoutube}
                  size={1.2}
                  className="mr-2 fill-current text-white hover:text-purp-100 transition duration-200 ease-in-out"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCMeEufGeiAWaxOsIS0Lwegg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  path={mdiLinkedin}
                  size={1.2}
                  className="fill-current text-white hover:text-purp-100 transition duration-200 ease-in-out"
                />
              </a>
            </div>
            <ButtonPurp link="/contact" variant="purp">
              Request a Quote
            </ButtonPurp>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-black text-center mx-auto py-2">
      <p className="text-white text-sm">
        Copyright {moment().format("YYYY")} | Kevin Smith Transportation Group
      </p>
    </div>
  </footer>
)
