import React from "react"

export const ButtonPurp = props => (
  <button
    className={`bg-purp-100 hover:bg-purp-200 px-5 py-3 text-white transition duration-200 ease-in-out
      ${props.oval ? "rounded-full" : ""}
      ${props.rounded ? "rounded-lg" : ""}
      ${props.shadow ? "shadow-purp" : ""}
      ${props.className ? props.className : null}
      `}
  >
    {props.children}
  </button>
)

export const Container = props => (
  <div
    className={`container mx-auto ${
      props.fluid ? "w-full" : "max-w-screen-lg"
    } ${props.className ? props.className : null}`}
    style={props.style}
  >
    {props.children}
  </div>
)

export const Row = props => (
  <div
    className={`row flex flex-wrap px-4 ${
      props.className ? props.className : null
    }`}
    style={props.style}
  >
    {props.children}
  </div>
)

export const Col = props => {
  let gutter
  if (props.gutter === "none") {
    gutter = "px-0"
  } else if (props.gutter === "sm") {
    gutter = "px-2"
  } else if (props.gutter === "lg") {
    gutter = "px-6"
  }

  return (
    <div
      className={`col ${props.gutter ? gutter : "px-4"} ${
        props.className ? props.className : null
      }`}
    >
      {props.children}
    </div>
  )
}

export const H1 = props => (
  <h1
    className={`text-4xl sm:text-6xl font-heading leading-none mb-2 ${props.color}`}
  >
    {props.children}
  </h1>
)

export const H2 = props => (
  <h2
    className={`text-3xl sm:text-5xl font-heading text-dark ${props.className}`}
  >
    {props.children}
  </h2>
)

export const H3 = props => (
  <h3
    className={`text-2xl sm:text-4xl font-heading text-dark leading-none ${props.className}`}
  >
    {props.children}
  </h3>
)

export const H4 = props => (
  <h4
    className={`text-xl sm:text-3xl font-heading text-dark leading-none ${props.className}`}
  >
    {props.children}
  </h4>
)

export const H5 = props => (
  <h5
    className={`text-lg sm:text-2xl font-heading text-dark leading-none ${props.className}`}
  >
    {props.children}
  </h5>
)

H1.defaultProps = {
  color: "text-white",
}

ButtonPurp.defaultProps = {
  shadow: false,
}

Row.defaultProps = {
  fluid: false,
}
