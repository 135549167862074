import React from "react"
import { Link } from "gatsby"

export default () => (
  <header>
    <div className="mx-auto bg-purp-100 py-2">
      <p className="text-center text-white">
        For our Post-COVID-19 Q&A{" "}
        <Link to="/covid-19-update" className="hover:underline">
          click here
        </Link>
      </p>
    </div>
    <div className="mx-auto bg-black py-3">
      <p className="text-white text-center text-xl">
        Open 24/7
        <a href="tel:6102226225" className="hover:underline pl-3">
          (610) 222-6225
        </a>
      </p>
      <div className="flex justify-center pt-2 pb-1">
        <Link to="/">
          <img
            src="/images/logos/kstg-weddings-logo.svg"
            alt="kstg logo"
            className="h-24"
          ></img>
        </Link>
      </div>
    </div>
  </header>
)
